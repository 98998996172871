<template>
  <div>
    <div class="title">
      <h1>孤独症综合评估系统</h1>
      <h2>嗨宝快学</h2>
    </div>

    <a-form-model
      id="formLogin"
      class="user-layout-login"
      ref="formLogin"
      :form="form"
      @submit="handleSubmit"
    >
      <a-alert
        v-if="isLoginError"
        type="error"
        showIcon
        style="margin-bottom: 24px;"
        message="账户或密码错误"
      />

      <a-form-model-item>
        <div class="label">账号</div>
        <div class="account">
          <img src="~@/assets/ico_account.png" />
          <a-input
            v-model="form.cellphone"
            type="text"
            placeholder="请输入您的账号"
            @change="checkValue($event)"
          ></a-input>
        </div>
      </a-form-model-item>

      <a-form-model-item>
        <div class="label">密码</div>
        <div class="password">
          <img src="~@/assets/ico_password.png" />
          <a-input-password id="antpassword" v-model="form.password" placeholder="请输入您的密码"></a-input-password>
        </div>
      </a-form-model-item>

      <a-form-model-item style="margin-top:24px">
        <a-button
          size="large"
          type="primary"
          htmlType="submit"
          :disabled="!isReady"
          :loading="loading"
          class="login-button"
        >登录</a-button>
      </a-form-model-item>
      <a-button class="change-pass" type="link" @click="changePassword">忘记密码？</a-button>
    </a-form-model>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { timeFix } from '@/utils/util'
import { ACCESS_TOKEN, REFRESH_TOKEN } from '@/store/mutation-types'
import storage from 'store'

export default {
  data() {
    return {
      isLoginError: false,
      loading: false,
      form: {
        cellphone: '',
        password: '',
      },
    }
  },
  created() {
    storage.remove(ACCESS_TOKEN)
    storage.remove(REFRESH_TOKEN)
  },
  computed: {
    isReady() {
      return this.form.cellphone && this.form.password
    },
  },
  methods: {
    ...mapActions(['login', 'logout']),
    handleSubmit(e) {
      e.preventDefault()
      const {
        form: { cellphone, password },
        login,
        loginSuccess,
      } = this

      this.loading = true
      login({
        cellphone,
        password,
      })
        .then((res) => {
          const { code, msg } = res
          if (code === 0) {
            loginSuccess()
          } else {
            this.$notification['error']({
              message: '错误',
              description: `${msg}`,
              duration: 10,
            })
          }
        })
        .finally(() => (this.loading = false))
    },
    loginSuccess(res) {
      // check res.homePage define, set $router.push name res.homePage
      // Why not enter onComplete
      /*
      this.$router.push({ name: 'analysis' }, () => {
        console.log('onComplete')
        this.$notification.success({
          message: '欢迎',
          description: `${timeFix()}，欢迎回来`
        })
      })
      */
      const {
        $router,
        $route: {
          query: { redirect },
        },
        $notification,
      } = this

      $router.push({ path: redirect || '/' })

      // 延迟 1 秒显示欢迎信息
      setTimeout(() => {
        $notification.success({
          message: '欢迎',
          description: `${timeFix()}，欢迎回来`,
        })
      }, 1000)
      this.isLoginError = false
    },
    requestFailed(err) {
      this.isLoginError = true
      this.$notification['error']({
        message: '错误',
        description: ((err.response || {}).data || {}).message || '请求出现错误，请稍后再试',
        duration: 4,
      })
    },
    checkValue(e) {
      const newPhone = e.target.value.replace(/[\s*]/g, '')
      this.form.cellphone = newPhone
    },
    // 修改密码
    changePassword() {
      this.$router.push({ name: 'changepassword' })
    },
  },
}
</script>

<style lang="less" scoped>
.title {
  h1 {
    margin: 0;
    font-family: PingFangSC-Medium, PingFang SC;
    font-size: 2.9vw;
    color: #3350c7;
    cursor: pointer;
  }

  h2 {
    margin: 1.2vh 0 0;
    font-family: PingFangSC-Regular, PingFang SC;
    font-size: 1.7vw;
    color: #7687d0;
    cursor: pointer;
  }
}
.user-layout-login {
  margin: 8vh 0 0;
  .label {
    margin: 0 0 16px;
    font-size: 24px;
    color: #3350c7;
    font-weight: bold;
  }

  .account {
    position: relative;
    width: 27.3vw;
    height: 6vh;
    border: 2px solid #d7ddf4;
    border-radius: 10px;

    img {
      position: absolute;
      top: 1.7vh;
      left: 0.8vw;
      max-width: 20px;
    }
    .ant-input {
      position: absolute;
      top: 0;
      left: 3.5vw;
      width: 80%;
      height: 100%;
      border: none;
      font-size: 1.25vw;
    }
    .ant-input:focus {
      box-shadow: none;
    }
  }
  .password {
    position: relative;
    width: 27.3vw;
    height: 6vh;
    border: 2px solid #d7ddf4;
    border-radius: 10px;

    img {
      position: absolute;
      top: 1.7vh;
      left: 0.8vw;
      max-width: 20px;
    }
    .ant-input-password {
      position: absolute;
      top: 0;
      left: 3.5vw;
      width: 80%;
      height: 6vh;
      border: none;
      font-size: 1.25vw;

      /deep/ #antpassword {
        height: 5.5vh;
        border: none;
        font-size: 1.25vw;
      }
    }

    /deep/ #antpassword:focus {
      box-shadow: none;
    }
  }

  .login-button {
    width: 27.3vw;
    height: 6.7vh;
    margin: 3.7vh 0 0;
    border: none;
    font-size: 1.7vw;
    color: #ffffff;
    background-color: #ff7a55;
    border-radius: 36px;
  }
  .change-pass {
    margin: 0 0 3.5vh 2vw;
    font-size: 22px;
  }
}
</style>
