<template>
  <div class="changepassword">
    <div class="title">
      <h1>修改密码</h1>
      <div class="back" @click="goback">返回</div>
    </div>

    <a-form-model class="user-layout-pass" ref="formPassword" :form="form">
      <a-form-model-item>
        <div class="label">手机号</div>
        <div class="account">
          <a-input
            v-model="form.cellphone"
            type="text"
            placeholder="请输入您的手机号"
            @change="checkValue($event, 'cellphone')"
            @blur="checkPhone($event)"
          ></a-input>
        </div>
      </a-form-model-item>
      <a-form-model-item>
        <div class="label">验证码</div>
        <div class="account">
          <a-input
            v-model="form.phonecode"
            type="text"
            placeholder="请输入验证码"
            @change="checkValue($event, 'phonecode')"
          ></a-input>
          <div class="sendcode" @click="sendPhoneCode">{{ sendText }}</div>
        </div>
      </a-form-model-item>
      <a-form-model-item>
        <div class="label">新密码</div>
        <div class="password">
          <a-input-password
            id="antpassword"
            v-model="form.newpassword"
            placeholder="6-12位字符(字母、数字、符号)，区分大小写"
            @change="checkValue($event, 'newpassword')"
            @blur="checkPass($event)"
          ></a-input-password>
        </div>
      </a-form-model-item>
      <a-form-model-item>
        <div class="label">确认新密码</div>
        <div class="password">
          <a-input-password
            id="checkantpassword"
            v-model="form.checkpassword"
            placeholder="请确认新密码"
            @change="checkValue($event, 'checkpassword')"
            @blur="checkPass($event)"
          ></a-input-password>
        </div>
      </a-form-model-item>

      <a-form-model-item>
        <a-button size="large" type="primary" @click="handleSubmit" class="pass-button">确定</a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { changeByPhone, sendCode } from '@/api/login'
import storage from 'store'

export default {
  data() {
    return {
      form: {
        cellphone: '',
        phonecode: '',
        newpassword: '',
        checkpassword: '',
      },
      sendText: '发送验证码',
      timer: null,
      canClick: true,
    }
  },
  methods: {
    goback() {
      this.$router.back()
    },
    vailData() {
      const {
        form: { cellphone, phonecode, newpassword, checkpassword },
      } = this
      let result = true
      if (!cellphone) {
        this.$notification.error({
          message: '提示',
          description: '请填写手机号',
        })
        result = false
      } else if (!phonecode) {
        this.$notification.error({
          message: '提示',
          description: '请填写验证码',
        })
        result = false
      } else if (!newpassword) {
        this.$notification.error({
          message: '提示',
          description: '请填写新密码',
        })
        result = false
      } else if (newpassword.length <= 5 || newpassword.length >= 13) {
        this.$notification.error({
          message: '提示',
          description: '密码为6-12位',
        })
        result = false
      } else if (newpassword !== checkpassword) {
        this.$notification.error({
          message: '提示',
          description: '两次密码不一致',
        })
        result = false
      }
      return result
    },
    handleSubmit(e) {
      e.preventDefault()
      const {
        form: { cellphone, phonecode, newpassword },
      } = this
      if (this.vailData()) {
        changeByPhone({ cellphone, password: newpassword, verificationCode: phonecode }).then((res) => {
          if (res.code === 0) {
            this.$notification.success({
              message: '提示',
              description: '密码修改成功',
            })
            const timer = setTimeout(() => {
              const loginPath = storage.get('loginPath')
              if (loginPath === '/user/login') {
                this.$router.push({ name: 'login' })
              } else {
                this.$router.push({ name: 'login/bjesr' })
              }
              clearTimeout(timer)
            }, 3000)
          } else {
            this.$notification.error({
              message: '提示',
              description: '密码修改失败',
            })
          }
        })
      }
    },
    sendPhoneCode() {
      const {
        form: { cellphone },
      } = this
      const reg = /^1[23456789]\d{9}$/g
      if (reg.test(cellphone)) {
        if (this.canClick) {
          sendCode({ cellphone }).then((res) => {
            if (res.code === 0) {
              this.canClick = false
              let count = 300
              this.timer = setInterval(() => {
                count--
                this.sendText = count + 's'
                if (count <= 0) {
                  clearInterval(this.timer)
                  this.canClick = true
                  return (this.sendText = '发送验证码')
                }
              }, 1000)
              this.$notification.success({
                message: '提示',
                description: '验证码发送成功，请注意查收',
              })
            }
          })
        }
      } else {
        this.$notification.error({
          message: '提示',
          description: '输入的手机号不正确',
        })
      }
    },
    checkValue(e, name) {
      const delspace = e.target.value.replace(/[\s*]/g, '')
      const newValue = delspace.replace(/[\u4E00-\u9FA5]/g, '')
      switch (name) {
        case 'cellphone':
          this.form[name] = newValue.slice(0, 11)
          break
        case 'phonecode':
          this.form[name] = newValue
          break
        case 'newpassword':
          this.form[name] = newValue
          break
        case 'checkpassword':
          this.form[name] = newValue
          break
        default:
      }
    },
    checkPhone(e) {
      const phoneLen = e.target.value.length
      if (phoneLen !== 11) {
        this.$notification.error({
          message: '提示',
          description: '输入的手机号不正确',
        })
      }
    },
    checkPass(e) {
      const passLen = e.target.value.length
      if (passLen <= 5 || passLen >= 13) {
        this.$notification.error({
          message: '提示',
          description: '密码为6-12位',
        })
      }
    },
  },
}
</script>

<style lang="less" scoped>
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h1 {
    height: 4vh;
    margin: 0;
    font-family: PingFangSC-Medium, PingFang SC;
    font-size: 24px;
    color: #3350c7;
  }
  .back {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 4vh;
    border: 2px solid #3350c7;
    font-size: 20px;
    color: #3350c7;
    border-radius: 10px;
    cursor: pointer;
  }
}
.user-layout-pass {
  margin: 2vh 0 0;
  .label {
    margin: 0 0 16px;
    font-size: 20px;
    color: #3350c7;
    font-weight: bold;
  }

  .account {
    position: relative;
    width: 27.3vw;
    height: 5vh;
    border: 2px solid #d7ddf4;
    border-radius: 10px;
    .ant-input {
      position: absolute;
      top: 0;
      left: 10px;
      width: 70%;
      height: 100%;
      border: none;
      font-size: 1vw;
    }
    .ant-input:focus {
      box-shadow: none;
    }
    .sendcode {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25%;
      height: 100%;
      border-left: 2px solid #d7ddf4;
      font-size: 1vw;
      color: #ffffff;
      background-color: #3350c7;
      cursor: pointer;
      border-radius: 0 10px 10px 0;
    }
  }
  .password {
    position: relative;
    width: 27.3vw;
    height: 5vh;
    border: 2px solid #d7ddf4;
    border-radius: 10px;
    .ant-input-password {
      position: absolute;
      top: 0;
      left: 10px;
      width: 95%;
      height: 5vh;
      border: none;
      font-size: 1vw;

      /deep/ #antpassword {
        height: 4.5vh;
        border: none;
        font-size: 1vw;
      }
      /deep/ #checkantpassword {
        height: 4.5vh;
        border: none;
        font-size: 1vw;
      }
    }

    /deep/ #antpassword:focus {
      box-shadow: none;
    }
    /deep/ #checkantpassword:focus {
      box-shadow: none;
    }
  }

  .pass-button {
    width: 27.3vw;
    height: 5vh;
    margin: 1.5vh 0;
    border: none;
    font-size: 1.2vw;
    color: #ffffff;
    background-color: #ff7a55;
    border-radius: 36px;
  }
}
</style>
